#whiteLabelImage {
  height: 50px;
  margin: 5px 10px;
}

.customTab {
  width: 100px;
}

.userName {
  max-width: 17vw;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userNameMobile {
  max-width: 20vw;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeDrop {
  color: rgb(73, 80, 87);
}

.logo-text {
  color: #2DA5E9;
}

.logo-text-beta {
  color: #FF8C00;
}

.nav-link.active {
  color: #0a58ca;
  background-color: none;
  border: none;
}